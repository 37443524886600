import React, { useState, useEffect } from "react";
import {
  TextField,
  Autocomplete,
  Button,
  Box,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import api from "../../services/api";

interface ReportData {
  reports: Array<{
    establishment: string;
    action: string;
    counts: number;
  }>;
  total: number;
}

export function AnalyticsReporter() {
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [search, setSearch] = useState("");
  const [event, setEvent] = useState("custom_played_video_2");
  const [options, setOptions] = useState<string[]>([]);
  const [reportData, setReportData] = useState<ReportData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const maxDays = 30;

  const validateDates = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= maxDays;
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    if (validateDates(newStartDate, endDate)) {
      setStartDate(newStartDate);
      setError("");
    } else {
      setError(`O intervalo de datas não pode exceder ${maxDays} dias.`);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    if (validateDates(startDate, newEndDate)) {
      setEndDate(newEndDate);
      setError("");
    } else {
      setError(`O intervalo de datas não pode exceder ${maxDays} dias.`);
    }
  };

  async function onSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    if (!validateDates(startDate, endDate)) {
      setError(`O intervalo de datas não pode exceder ${maxDays} dias.`);
      return;
    }
    setLoading(true);

    try {
      const response = await api.get(
        `/app/analytics-report?start=${startDate}&end=${endDate}&search=${search}&eventName=${event}`
      );
      setReportData(response.data);
    } catch (error) {
      console.error("Error fetching analytics report:", error);
    } finally {
      setLoading(false);
    }
  }

  async function handleAutoComplete(searchTerm: string): Promise<void> {
    const response = await api.get(
      `/app/search-establishment?search=${searchTerm}`
    );
    setOptions(response.data);
  }

  useEffect(() => {
    if (search) {
      handleAutoComplete(search);
    }
  }, [search]);

  return (
    <Container>
      <Typography variant="h4" textAlign={"center"}>
        Analytics Reporter
      </Typography>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
      >
        <TextField
          type="date"
          label="Data Inicial"
          value={startDate}
          onChange={handleStartDateChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="date"
          label="Data Final"
          value={endDate}
          onChange={handleEndDateChange}
          InputLabelProps={{ shrink: true }}
        />
        <Autocomplete
          freeSolo
          options={options}
          inputValue={search}
          onInputChange={(event, newInputValue) => setSearch(newInputValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Estabelecimento"
              placeholder="Digite 'UF', 'Cidade' ou 'Estabelecimento'"
            />
          )}
        />
        <FormControl>
          <InputLabel id="event-label">Evento</InputLabel>
          <Select
            labelId="event-label"
            value={event}
            onChange={(e) => setEvent(e.target.value)}
            label="Evento"
          >
            <MenuItem value="custom_played_video_2">
              Visualização de vídeo
            </MenuItem>
            <MenuItem value="custom_downloaded_video_2">
              Download de vídeo
            </MenuItem>
          </Select>
        </FormControl>
        {error && <Alert severity="error">{error}</Alert>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Buscar"}
        </Button>
      </Box>
      {reportData && (
        <>
          <Typography variant="h6" textAlign={"center"} sx={{ mt: 4 }}>
            Total de eventos: {reportData.total}
          </Typography>
          {reportData?.reports.length > 0 ? (
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Estabelecimento</TableCell>
                    <TableCell>Evento</TableCell>
                    <TableCell>Quantidade</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.reports.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.establishment}</TableCell>
                      <TableCell>{row.action}</TableCell>
                      <TableCell>{row.counts}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            !loading && (
              <Typography variant="h6" textAlign={"center"} sx={{ mt: 4 }}>
                Nenhum dado encontrado.
              </Typography>
            )
          )}
        </>
      )}
    </Container>
  );
}
