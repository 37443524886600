import React from "react";
import Dropzone from "react-dropzone";

import Container from "./styles";

export default function Upload({
  title,
  onUpload,
  disabled,
  maxFiles = undefined,
}: any) {
  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return <span>{title}</span>;
    }

    if (isDragReject) {
      return <span>Arquivo não suportado</span>;
    }

    return <span>Solte os arquivos aqui</span>;
  };

  return (
    <Dropzone
      accept={["image/png", "image/jpg", "image/gif"]}
      onDropAccepted={onUpload}
      disabled={disabled}
      maxFiles={maxFiles}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <Container className="input" {...getRootProps()}>
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </Container>
      )}
    </Dropzone>
  );
}
