import React from "react";
import { Routes, Route } from "react-router-dom";
import { AddBlock } from "../views/AddBlock";
import { EditBlock } from "../views/EditBlock";
import { VisitorsList } from "../views/VisitorsList";
import EstablishmentList from "../views/EstablishmentList";
import { Layout } from "../components/Layout";
import EstablishmentHashList from "../views/EstablishmentHashList";
import { AnalyticsReporter } from "../views/AnalyticsReporter";

export function AppRoutes() {
  return (
    <Layout title="Geral">
      <Routes>
        <Route path="/" element={<EstablishmentList />} />
        <Route path="/estabelecimentos" element={<EstablishmentList />} />
        <Route path="/cadastrar-estabelecimento" element={<AddBlock />} />
        <Route path="/editar-estabelecimento/:id" element={<EditBlock />} />
        <Route path="/usuarios" element={<VisitorsList />} />
        <Route path="/settings/links" element={<EstablishmentHashList />} />
        <Route path="/analytics-reporter" element={<AnalyticsReporter />} />
        <Route path="*" element={<h1>Página não encontrada</h1>} />
      </Routes>
    </Layout>
  );
}
