import React, { useEffect, useState } from "react";
import DraggableResizableLogo, {
  DraggableResizableLogoProps,
} from "./DraggableResizableLogo";
import interact from "interactjs";

export type ImageProps = {
  index: number;
  name: string;
  image: any;
  rule: string;
  initialPosition: DraggableResizableLogoProps["initialPosition"];
  initialized: boolean;
};

interface BaseProps {
  isAdmin: boolean;
  baseImage: any;
  channelId: string;
}

export interface LogosProps {
  [key: string]: ImageProps;
}

type Position = {
  y: number;
  x: number;
  width: number;
  height: number;
};

const DraggableResizableLogos: React.FC<
  BaseProps & {
    logos: LogosProps;
    onLogoPositionChange: (
      channelId: string,
      imagePath: string,
      position: Position
    ) => void;
  }
> = ({ isAdmin, baseImage, channelId, logos, onLogoPositionChange }) => {
  const [logosListWithoutRule, setLogosListWhitoutRule] = useState<any[]>([]);
  const [logosListWithRule, setLogosListWhitRule] = useState<any[]>([]);
  const [scale, setScale] = useState<{ w: number; h: number }>({ w: 1, h: 1 });
  const [visualDimensions, setVisualDimensions] = useState<
    { w: number; h: number } | undefined
  >(undefined);

  useEffect(() => {
    const target = document.getElementById("cam-layout");
    const width = target?.offsetWidth || baseImage.width;
    const height = target?.offsetHeight || baseImage.height;
    setVisualDimensions({ w: width, h: height });
    setScale({ w: baseImage.width / width, h: baseImage.height / height });
  }, []);

  useEffect(() => {
    interact(".dropzone").dropzone({
      accept: ".resize-drag",
      overlap: 0.75,
      ondropactivate: function (event) {
        event.target.classList.add("drop-active");
      },
      ondragenter: function (event) {
        const draggableElement = event.relatedTarget;
        const dropzoneElement = event.target;

        dropzoneElement.classList.add("drop-target");
        draggableElement.classList.add("can-drop");
      },
      ondragleave: function (event) {
        event.target.classList.remove("drop-target");
        event.relatedTarget.classList.remove("can-drop");
      },
      ondropdeactivate: function (event) {
        event.target.classList.remove("drop-active");
        event.target.classList.remove("drop-target");
      },
    });
  }, []);
  const renderLogos = (
    index: number,
    id: string,
    logo: DraggableResizableLogoProps | ImageProps | undefined
  ) => {
    if (logo !== undefined) {
      return (
        visualDimensions && (
          <DraggableResizableLogo
            key={id}
            id={id}
            channelId={channelId}
            index={index}
            image={logo.image}
            rule={logo.rule}
            initialized={logo.initialized}
            onPositionChange={onLogoPositionChange}
            initialPosition={logo.initialPosition}
            baseImage={baseImage}
            scale={scale}
            parentDimensions={visualDimensions}
          />
        )
      );
    }
    return null;
  };

  const updateLogosLists = () => {
    const withoutRule: any[] = [];
    const withRule: any[] = [];

    Object.keys(logos).forEach((key, index) => {
      const logo = logos[key];
      if (logo) {
        if (logo.rule === "half-right" && !isAdmin) {
          withRule.push(renderLogos(index, key, logo));
        } else {
          withoutRule.push(renderLogos(index, key, logo));
        }
      }
    });

    setLogosListWhitoutRule(withoutRule);
    setLogosListWhitRule(withRule);
  };

  useEffect(() => {
    updateLogosLists();
  }, [logos, visualDimensions]);

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      id="cam-layout"
    >
      {baseImage && "data" in baseImage && (
        <img
          src={baseImage.data as string}
          alt="Base"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      )}
      {!isAdmin && (
        <div
          className="dropzone"
          style={{
            position: "absolute",
            top: 0,
            left: "10%",
            width: "90%",
            height: "99%",
            border: "2px dashed #ccc",
            boxSizing: "border-box",
          }}
        >
          {logosListWithRule}
        </div>
      )}
      <div
        className="dropzone"
        style={{
          transform: "rotate(180deg)" /* Rotaciona o componente em 90 graus */,
          transformOrigin: "center" /* Define o ponto de origem da rotação */,
          position: "absolute",
          top: 0,
          left: "0%",
          width: "100%",
          height: "99%",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 6.5104%)", // Cria colunas de 250px
          gridTemplateRows: "repeat(auto-fill, 6.75925%)", // Cria linhas de 146px
          gap: "0", // Pode ser ajustado se você quiser espaçamento entre os blocos
          boxSizing: "border-box",
          backgroundImage:
            "linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to bottom, white 1px, transparent 1px)", // Linhas azuis
          backgroundSize: "6.5104% 6.75925%", // Tamanho do padrão do background
          direction: "rtl", // Inverte a direção do grid
        }}
      ></div>
      {logosListWithoutRule}
    </div>
  );
};

export default DraggableResizableLogos;
