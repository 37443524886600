export interface Image extends File {
  initialPosition: { y: number; x: number; width?: number; height?: number };
  index: number;
  id: string;
  url: string;
  path: string;
  data: string;
  initialized?: boolean;
  baseImage: any;
  width: number;
  height: number;
  x: number;
  y: number;
  originalName?: string;
}

export interface LogosProps {
  [key: string]: {
    index: number;
    name: string;
    image: Image;
    rule: string;
    initialized: boolean;
    initialPosition: {
      y: number;
      x: number;
      width?: number;
      height?: number;
    };
  };
}

interface UseLogosPropsParams {
  advertisersLogos: Array<Image>;
  isAdmin: boolean;
}

const useLogosProps = ({
  advertisersLogos,
  isAdmin,
}: UseLogosPropsParams): LogosProps => {
  const updateLogosProps = () => {
    const newLogosProps: LogosProps = {};

    const addLogoProps = (name: string, images: Image[], rule: string) => {
      images?.forEach((image, index) => {
        newLogosProps[index] = {
          index,
          name,
          image,
          initialized: true,
          rule,
          initialPosition: {
            y: image.y,
            x: image.x,
            width: image.width,
            height: image.height,
          },
        };
      });
    };

    if (advertisersLogos) {
      addLogoProps(
        "advertisersLogos",
        advertisersLogos,
        isAdmin ? "logo" : "half-right"
      );
    }
    return newLogosProps;
  };

  return updateLogosProps();
};

export default useLogosProps;
