import React, { useEffect, useState } from 'react';
import interact from 'interactjs';

interface Image extends File {
    url: string;
    path: string;
    data: string;
}

export type DraggableResizableLogoProps = {
    id: string;
    initialized: boolean;
    baseImage: {
        originalWidth: number;
        originalHeight: number;
        width: number;
        height: number;
    };
    index: number;
    channelId: string;
    image: Image;
    rule: string;
    initialPosition: {
        y: number;
        x: number;
        width?: number;
        height?: number;
    };
    scale: { w: number; h: number };
    parentDimensions: { w: number; h: number };
    onPositionChange: (
        channelId: string,
        imagePath: string,
        position: { y: number; x: number; width: number; height: number }
    ) => void;
};

const DraggableResizableLogo: React.FC<DraggableResizableLogoProps> = ({
    id,
    initialized,
    baseImage,
    index,
    channelId,
    image,
    rule,
    initialPosition,
    scale,
    onPositionChange,
    parentDimensions
}) => {
    // const [position, setPosition] = useState(initialPosition);
    const [scalonedDimensions, setScalonedDimensions] = useState<DraggableResizableLogoProps["initialPosition"]>(initialPosition);
    const [firstPosition, setFirstPosition] = useState(initialPosition);

    if (!firstPosition.x || !firstPosition.y){
        setFirstPosition({...firstPosition, x: 100, y: 100, width: firstPosition.width || 200, height: firstPosition.height || 200})
    }
    const calculateScaledPosition = (
        x: number,
        y: number,
        width: number,
        height: number,
        parentWidth: number,
        parentHeight: number
    ) => {

        const scaleX = baseImage.width / parentWidth;
        const scaleY = baseImage.height / parentHeight;
        const reX =  (firstPosition.x) + x  * scaleX//- (width / 2)
        const reY = (firstPosition.y) + y  * scaleY//- (height / 2)
        const relativeH = Math.sqrt((height * scaleY) ** 2)
        const relativeW = Math.sqrt((width * scaleX) ** 2)
        return {
            relativeX: Math.sqrt((reX ) ** 2),
            relativeY: Math.sqrt((reY ) ** 2),
            relativeW,
            relativeH,
        };
    };

    const applySizeLimit = (
        width: number,
        height: number,
        parentWidth: number,
        parentHeight: number
    ) => {
        const maxWidth = parentWidth * 1;
        const maxHeight = parentHeight * 1;

        return {
            limitedWidth: Math.min(width, maxWidth),
            limitedHeight: Math.min(height, maxHeight),
        };
    };

    useEffect(() => {

        const dragMoveListener = (event: any) => {
            const target = event.target;
            // const parent = target.parentElement;

            let x = (parseFloat(target.getAttribute('data-x'))) + event.dx;
            let y = (parseFloat(target.getAttribute('data-y'))) + event.dy;

            target.style.transform = `translate(${x}px, ${y}px)`;
            target.setAttribute('data-x', x.toString());
            target.setAttribute('data-y', y.toString());

            // setPosition({ x, y, width: target.offsetWidth, height: target.offsetHeight });
        };

        const dragEndListener = (event: any) => {
            const target = event.target;
            const parent = target.parentElement;

          // console.log(' parseFloat(target.getAttribute)', firstPosition)
            const { relativeX, relativeY, relativeW, relativeH } = calculateScaledPosition(
                parseFloat(target.getAttribute('data-x')),
                parseFloat(target.getAttribute('data-y')),
                target.offsetWidth,
                target.offsetHeight,
                parent.offsetWidth,
                parent.offsetHeight
            );
            onPositionChange(channelId, target.id, {
                y: relativeY,
                x: relativeX,
                width: relativeW,
                height: relativeH,
            });
        };

        const resizeListener = (event: any) => {
            const target = event.target;
            const parent = target.parentElement;

            let x = parseFloat(target.getAttribute('data-x'));
            let y = parseFloat(target.getAttribute('data-y'));

            x += event.deltaRect.left;
            y += event.deltaRect.top;

            let newWidth = event.rect.width;
            let newHeight = event.rect.height;

            const { limitedWidth, limitedHeight } = applySizeLimit(
                newWidth,
                newHeight,
                parent.offsetWidth,
                parent.offsetHeight
            );
            //console.log('  limitedWidth, limitedHeight ',  limitedWidth, limitedHeight )
            target.style.width = `${limitedWidth}px`;
            target.style.height = `${limitedHeight}px`;
            target.style.transform = `translate(${x}px, ${y}px)`;
            target.setAttribute('data-x', x.toString());
            target.setAttribute('data-y', y.toString());

            // const { relativeX, relativeY, relativeW, relativeH } = calculateScaledPosition(
            //     x,
            //     y,
            //     limitedWidth,
            //     limitedHeight,
            //     parent.offsetWidth,
            //     parent.offsetHeight
            // );

            // setPosition({
            //     x: relativeX,
            //     y: relativeY,
            //     width: relativeW,
            //     height: relativeH,
            // });
        };

        const resizeEndListener = (event: any) => {
            const target = event.target;
            const parent = target.parentElement;

            const { relativeX, relativeY, relativeW, relativeH } = calculateScaledPosition(
                parseFloat(target.getAttribute('data-x')),
                parseFloat(target.getAttribute('data-y')),
                target.offsetWidth,
                target.offsetHeight,
                parent.offsetWidth,
                parent.offsetHeight
            );

            onPositionChange(channelId, target.id, {
                y: relativeY,
                x: relativeX,
                width: relativeW,
                height: relativeH,
            });
        };

        interact('.resize-drag')
            .resizable({
                edges: { left: true, right: true, bottom: true, top: true },
                listeners: { move: resizeListener, end: resizeEndListener },
                modifiers: [
                    interact.modifiers.restrictEdges({
                        outer: 'parent',
                    }),
                    interact.modifiers.restrictSize({
                        min: { width: 50, height: 50 },
                    }),
                ],
                inertia: true,
            })
            .draggable({
                listeners: { move: dragMoveListener, end: dragEndListener },
                inertia: true,
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: 'parent',
                        endOnly: true,
                    }),
                ],
            });

        return () => {
            interact('.resize-drag').unset();
        };
    }, [image]);

    useEffect(() => {
        if (firstPosition) {
            const escalonedDimensions = {
                width: (firstPosition.width || (scale.w * parentDimensions.w / 2)) / scale.w,
                height: (firstPosition.height || (scale.h * parentDimensions.h / 2)) / scale.h,
                y: firstPosition.y / scale.h,
                x: firstPosition.x / scale.w
            }
            escalonedDimensions.width = escalonedDimensions.width > parentDimensions.w ? parentDimensions.w * 0.5 : escalonedDimensions.width
            escalonedDimensions.height = escalonedDimensions.height > parentDimensions.h ? parentDimensions.h * 0.5 : escalonedDimensions.height
            escalonedDimensions.y = escalonedDimensions.y > parentDimensions.h ? parentDimensions.h * 0.5 : escalonedDimensions.y
            escalonedDimensions.x = escalonedDimensions.x > parentDimensions.w ? parentDimensions.w * 0.5 : escalonedDimensions.x
            setScalonedDimensions(escalonedDimensions)
        }
    }, [firstPosition])
    return (
        scalonedDimensions && <div
            className="resize-drag"
            id={image.path}
            style={{
                position: 'absolute',
                top: `${scalonedDimensions.y}px`,
                left: `${scalonedDimensions.x}px`,
                width: `${scalonedDimensions.width}px`,
                height: `${scalonedDimensions.height}px`,
                touchAction: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: `${parentDimensions.w}px`,
                maxHeight: `${parentDimensions.h}px`
            }}
            data-x="0"
            data-y="0"
        >
            <img
                src={image?.data || image?.url}
                alt="Logo"
                className="resize-handle"
                style={{ width: '100%', height: '100%', touchAction: 'none' }}
            />
        </div>
    );
};

export default DraggableResizableLogo;
