import React from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "../views/Login";

export function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<h1>Página não encontrada</h1>} />
    </Routes>
  );
}
